import React from 'react'
import { IEventCardSkeletonProps } from './types'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const EventCardSkeleton: React.FC<IEventCardSkeletonProps> = ({}) => {
  const imageStyle: React.CSSProperties = {
    height: '210px',
    borderRadius: '10px 40px 0 0',
  }
  const contentLayout: React.CSSProperties = {
    padding: '16px 8px',
  }
  const labels: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
  }
  const labelCategory: React.CSSProperties = {
    height: '26px',
    width: '73px',
  }
  const labelIsFree: React.CSSProperties = {
    height: '26px',
    width: '151px',
  }
  const titleLayout: React.CSSProperties = {
    margin: '12px 0',
  }
  const title: React.CSSProperties = {
    height: '26px',
    width: '100%',
    margin: '4px 0',
  }
  const infosLayout: React.CSSProperties = {
    margin: '4px 0',
  }
  const info: React.CSSProperties = {
    height: '26px',
    width: '179px',
    margin: '4px 0',
  }

  return (
    <SkeletonTheme>
      <Skeleton count={1} style={imageStyle} />
      <div style={contentLayout}>
        <div style={labels}>
          <Skeleton count={1} style={labelCategory} />
          <Skeleton count={1} style={labelIsFree} />
        </div>
        <div style={titleLayout}>
          <Skeleton count={2} style={title} />
        </div>
        <div style={infosLayout}>
          <Skeleton count={2} style={info} />
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default EventCardSkeleton
