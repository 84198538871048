import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'

import { tr } from 'date-fns/locale/tr'
import { enGB } from 'date-fns/locale/en-GB'

import { functions, useApp } from '@wap-client/core'

import Icon from '@/components/base/icon'
import Button from '@/components/base/button/Button'

import { getCategories, getFestivals, getPlaces } from '../services'

import {
  ICategoryFiltered,
  IDateFiltered,
  IFestival,
  IFestivalFiltered,
  IPlaceFiltered,
  IPriceFiltered,
} from '../event-filter/types'
import { IFilterPanelProps, ISelectedState } from './types'
import { getThisMonth, getThisWeek, queryDatesConvert } from '../costants'
import { useRouter } from 'next/router'
import { usePathname } from 'next/navigation'
import { queryService } from '@wap-client/services'

const EventFilterPanel: React.FunctionComponent<IFilterPanelProps> = ({
  setIsPanel,
  isPanel,
  festivalID,
  isArchivePage,
}) => {
  const app = useApp()
  const [festivals, setFestivals] = useState<Array<IFestival>>([])
  const [filterFestival, setFilterFestival] = useState<IFestival[]>([])
  const [categories, setCategories] = useState<Array<ICategoryFiltered>>([])
  const [places, setPlaces] = useState<Array<ICategoryFiltered>>([])
  const [datePicker, setDatePicker] = useState<Date | null>(null)
  const [filter, setFilter] = useState<{ [key: string]: string | boolean }>({})
  const [isSelected, setIsSelected] = useState(false)
  const [selected, setSelected] = useState<ISelectedState>({
    data: [],
    title: '',
    param: '',
  })
  const [allYears, setAllYears] = useState<string[]>([])
  const [pickYear, setPickYear] = useState<string>(
    String(new Date().getFullYear())
  )

  const router = useRouter()
  const pathname2 = usePathname()
  const date = moment().format('2024-04-14[T]00:00:00')

  const priceList = [
    {
      baslik: app.settings.translations['free'],
      value: true,
    },
    {
      baslik: app.settings.translations['paid'],
      value: false,
    },
  ]

  const getAllYears = useCallback(async () => {
    try {
      const response = await queryService.run<
        Array<{ id: string; bitisTarihi: string }>
      >(app.environment, {
        name: 'kty-etkinlikler-get-etkinlik-yillari-veri-sorgusu',
        language: app.language,
        query: '',
      })

      if (response && response?.data.length > 0) {
        const getYearsReduce = response.data.reduce(
          (years: string[], event) => {
            const convertDate = String(moment(event?.bitisTarihi).get('year'))
            if (!years.includes(convertDate) && !isNaN(+convertDate)) {
              years.push(convertDate)
            }

            return years
          },
          []
        )
        setAllYears(getYearsReduce)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    getAllYears()
  }, [getAllYears])

  // Get Festival & Categories
  useEffect(() => {
    getFestivals(app).then((res) => res && setFestivals(res))
    getCategories(app).then((res) => res && setCategories(res))
  }, [app, app.language])

  // Check Router Parameters & add filter
  useEffect(() => {
    const { pathname, ...filterParams }: any = app.params
    setFilter({ ...filterParams })

    if (
      isArchivePage &&
      router.query?.dates === 'pickYear' &&
      router.query?.startDate
    ) {
      const convertYear = String(moment(router.query.startDate).get('year'))
      setPickYear(convertYear)
    } else if (isArchivePage && !router.query?.dates) {
      setPickYear(String(new Date().getFullYear()))
    } else if (
      isArchivePage &&
      !router.query?.startDate &&
      router.query?.endDate
    ) {
      setPickYear('')
    }
  }, [app.params, router, app])

  // Check if festival selected and get places
  useEffect(() => {
    if (filter.festivalId) {
      const value =
        typeof filter.festivalId === 'string'
          ? filter.festivalId
          : filter.festivalId.toString()
      getPlaces(app, {
        name: 'festivalId',
        value: value,
      }).then((res) => {
        if (res && res.length > 0) {
          setPlaces(res)
        } else {
          setPlaces([])
          deleteFilter('placeId')
        }
      })
    } else {
      setPlaces([])
      filter?.placeId && deleteFilter('placeId')
    }
  }, [app, app.language, filter])

  useEffect(() => {
    filterFestivals(festivals)
  }, [festivals])

  const filterFestivals = (festivals: IFestival[]) => {
    const now: IFestival[] = []
    const feture: IFestival[] = []

    festivals.map((item: IFestival) => {
      if (item.baslangicTarihi && item.bitisTarihi) {
        if (item.baslangicTarihi <= date && item.bitisTarihi >= date) {
          now.push(item)
        } else {
          feture.push(item)
        }
      }
    })

    setFilterFestival([...now, ...feture])
  }

  const handleButtonClick = (param: string, id: string) => {
    const newFilter: { [key: string]: string } = {}
    if (param && id) {
      newFilter[param] = id
      if (param === 'festivalId') {
        delete filter['placeId']
        setFilter((prevFilter) => ({
          ...prevFilter,
          ...newFilter,
        }))
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          ...newFilter,
        }))
      }
    }
  }

  const deleteFilter = (param: string) => {
    const updatedFilter = { ...filter }

    if (updatedFilter[param] !== undefined) {
      if (param === 'dates') {
        delete updatedFilter['startDate']
        delete updatedFilter['endDate']
      }
      delete updatedFilter[param]
      setFilter(updatedFilter)
    }
  }

  const handleDateButtonClick = (
    startDate: string,
    endDate: string,
    id: string
  ) => {
    setDatePicker(null)
    if (id === '4') {
      setFilter((prevFilter) => ({
        ...prevFilter,
        startDate: '',
        endDate,
        dates: queryDatesConvert(id),
      }))
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        startDate,
        endDate,
        dates: queryDatesConvert(id),
      }))
    }
  }

  const handlePriceButtonClick = (isFree: boolean) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      isFree: isFree,
    }))
  }

  const handleDatePicker = (startDate: string, endDate: string, id: string) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate,
      endDate,
      dates: queryDatesConvert(id),
    }))
  }

  const handleSelectionButtonClick = (
    data:
      | ICategoryFiltered[]
      | IDateFiltered[]
      | IFestivalFiltered[]
      | IPlaceFiltered[]
      | IPriceFiltered[],
    title: string,
    param: string
  ) => {
    setIsSelected(!isSelected)
    setSelected({
      data: data,
      title: title,
      param: param,
    })
  }

  const clearFilter = () => {
    setFilter({})
    setPlaces([])
    setSelected({
      data: [],
      title: '',
      param: '',
    })
    setDatePicker(null)
    handleFilter({})

    if (isArchivePage) {
      const params = new URLSearchParams(window.location.search)
      const nowYear = new Date().getFullYear()
      const endYear = moment.utc().year(nowYear).endOf('year').toISOString()
      params.set('dates', 'pickYear')
      params.delete('startDate')
      params.set('endDate', endYear)

      router.push(pathname2 + '?' + params.toString(), undefined, {
        scroll: false,
      })
    }
  }

  const renderDatePicker = () => {
    const langShortCode = app.language.substring(0, 2)
    registerLocale(
      langShortCode === 'tr' ? 'tr' : 'enGB',
      langShortCode === 'tr' ? tr : enGB
    )

    const handleChanceDate = (date: Date | null) => {
      if (date) {
        const isoDate = moment(date).toISOString()

        const start = moment(isoDate).startOf('day').format().split('+')[0]
        const end = moment(isoDate).endOf('day').format().split('+')[0]

        handleDatePicker(start, end, '999')
      }

      setDatePicker(date)
    }

    return (
      <div
        className={functions.classnames(
          'filter-calendar',
          datePicker && 'selected'
        )}
      >
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={datePicker}
          onChange={(date: Date) => handleChanceDate(date)}
          className="date-picker"
          placeholderText={app.settings.translations['searchDate']}
          locale={langShortCode === 'tr' ? 'tr' : 'enGB'}
          dateFormat="dd/MM/yyyy"
          inline
        />
      </div>
    )
  }

  const renderDatePickerYears = () => {
    const langShortCode = app.language.substring(0, 2)
    registerLocale(
      langShortCode === 'tr' ? 'tr' : 'enGB',
      langShortCode === 'tr' ? tr : enGB
    )

    const handleChanceDate = (date: Date | null) => {
      const params = new URLSearchParams(window.location.search)
      params.delete('startDate')
      params.delete('endDate')
      params.delete('oldYearStartDate')
      params.delete('oldYearEndDate')

      if (date) {
        const nowYear = moment.utc().year().toString()
        const year = date.getFullYear()
        const startYear = moment.utc().year(year).startOf('year').toISOString()
        const endYear =
          String(new Date().getFullYear()) !== String(year)
            ? moment.utc().year(year).endOf('year').toISOString()
            : moment.utc(new Date()).toISOString()

        if (nowYear === year.toString()) {
          params.set('dates', 'pickYear')
          params.set('endDate', endYear)
          params.set('startDate', startYear)
        } else {
          params.set('dates', 'pickYear')
          params.set('oldYearEndDate', endYear)
          params.set('oldYearStartDate', startYear)
        }
      }

      router.replace(
        pathname2 + `${params.toString() && '?' + params.toString()}`,
        undefined,
        {
          scroll: false,
        }
      )

      setPickYear(String(date?.getFullYear() ?? null))
      setIsPanel(false)
    }

    return (
      <div
        className={functions.classnames(
          'filter-calendar',
          'mobile-date-picker-year',
          datePicker && 'selected'
        )}
      >
        <DatePicker
          closeOnScroll={(e) => e.target === document}
          selected={
            pickYear !== '' ? moment().year(Number(pickYear)).toDate() : null
          }
          onChange={(date: Date) => handleChanceDate(date)}
          className="date-picker"
          placeholderText={app.settings.translations['searchDate']}
          locale={langShortCode === 'tr' ? 'tr' : 'enGB'}
          dateFormat="yyyy"
          inline
          showYearPicker
          yearItemNumber={allYears.length ?? 3}
          minDate={moment
            .utc()
            .year(Math.min(...allYears.map((year) => Number(year))))
            .toDate()}
          maxDate={moment
            .utc()
            .year(Math.max(...allYears.map((year) => Number(year))))
            .toDate()}
        />
      </div>
    )
  }

  const renderSelection = () => {
    return (
      <div
        className={functions.classnames(
          'event-filter-panel__selection',
          isSelected && 'event-filter-panel__selection--active'
        )}
      >
        <div className="event-filter-panel__head">
          <Button
            onClick={() => {
              setIsSelected(!isSelected),
                setSelected({
                  data: [],
                  title: '',
                  param: '',
                })
            }}
            className="-button"
          >
            <Icon name="chev-left" />
            {selected.title}
          </Button>
        </div>

        <div className="event-filter-panel__selection__options">
          {selected.param === 'placeId' && !filter?.festivalId ? (
            <div className="event-filter-panel__selection__options__alert">
              <Icon name="help" size="medium" />
              {app.settings.translations['festivalFirst']}
            </div>
          ) : selected.param === 'placeId' && selected.data.length === 0 ? (
            <div className="event-filter-panel__selection__options__alert">
              <Icon name="help" size="medium" />
              {app.settings.translations['notFoundPlace']}
            </div>
          ) : selected.param === 'dates' ? (
            (selected.data as IDateFiltered[]).map((item, index) => (
              <div
                className="event-filter-panel__selection__options__option"
                key={index}
              >
                <Button
                  onClick={() => {
                    if (item.startDate && item.endDate && item.id) {
                      handleDateButtonClick(
                        item.startDate,
                        item.endDate,
                        item.id
                      ),
                        setIsSelected(false)
                    }
                  }}
                  className={functions.classnames(
                    item.id &&
                      queryDatesConvert(item.id) === filter[selected.param] &&
                      'selected'
                  )}
                >
                  {item.baslik}
                </Button>

                {item.id &&
                  queryDatesConvert(item.id) === filter[selected.param] && (
                    <Button
                      className="clear"
                      onClick={() => {
                        deleteFilter(selected.param)
                      }}
                    >
                      <Icon name="close" />
                    </Button>
                  )}
              </div>
            ))
          ) : selected.param === 'isFree' ? (
            (selected.data as IPriceFiltered[]).map((item, index) => (
              <div
                className="event-filter-panel__selection__options__option"
                key={index}
              >
                <Button
                  onClick={() => {
                    handlePriceButtonClick(item.value), setIsSelected(false)
                  }}
                  className={functions.classnames(
                    item.value === filter[selected.param] && 'selected'
                  )}
                >
                  {item.baslik}
                </Button>

                {item.value === filter[selected.param] && (
                  <Button
                    className="clear"
                    onClick={() => {
                      deleteFilter(selected.param)
                    }}
                  >
                    <Icon name="close" />
                  </Button>
                )}
              </div>
            ))
          ) : (
            selected.data.map((item, index) => (
              <div
                className="event-filter-panel__selection__options__option"
                key={index}
              >
                <Button
                  className={functions.classnames(
                    item.id === filter[selected.param] && 'selected'
                  )}
                  onClick={() => {
                    handleButtonClick(selected.param, item.id || ''),
                      setIsSelected(false)
                    selected.param === 'filterId' && setPlaces([])
                  }}
                  key={index}
                >
                  {item.baslik}

                  {selected.param === 'festivalId'
                    ? item.baslangicTarihi &&
                      item.bitisTarihi &&
                      item.baslangicTarihi >= date &&
                      item.bitisTarihi <= date && (
                        <span className="now">Aktif</span>
                      )
                    : null}
                </Button>

                {item.id === filter[selected.param] && (
                  <Button
                    className="clear"
                    onClick={() => {
                      deleteFilter(selected.param)
                    }}
                  >
                    <Icon name="close" />
                  </Button>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    )
  }

  const handleFilter = (defaultFilter?: any) => {
    const queryString = Object.keys(defaultFilter || filter)
      .filter((key) => filter[key as keyof typeof filter] !== '')
      .map((key) => `${key}=${filter[key as keyof typeof filter]}`)
      .join('&')

    router.push(pathname2 + '?' + queryString, undefined, {
      scroll: false,
    })

    setIsPanel(false)
  }

  const dateList = [
    {
      baslik: app.settings.translations['dateToday'],
      id: '0',
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },
    {
      baslik: app.settings.translations['dateTomorrow'],
      id: '1',
      startDate: moment().add(1, 'day').toISOString(),
      endDate: moment().add(1, 'day').toISOString(),
    },
    {
      baslik: app.settings.translations['dateWeek'],
      id: '2',
      startDate: getThisWeek('start'),
      endDate: getThisWeek('end'),
    },
    {
      baslik: app.settings.translations['dateMonth'],
      id: '3',
      startDate: getThisMonth('start'),
      endDate: getThisMonth('end'),
    },
    {
      baslik: app.settings.translations['dateArchive'],
      id: '4',
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },
  ]

  return (
    <div
      className={functions.classnames(
        'event-filter-panel',
        isPanel && 'event-filter-panel--active'
      )}
    >
      {renderSelection()}
      <div className="event-filter-panel__head">
        <Button onClick={() => setIsPanel(!isPanel)}>
          <Icon name="chev-left" />
          {app.settings.translations['filter']}
        </Button>

        <Button size="none" className="button--clear" onClick={clearFilter}>
          {app.settings.translations['clear']}
        </Button>
      </div>
      <div className="event-filter-panel__calendar">
        {!isArchivePage ? renderDatePicker() : renderDatePickerYears()}
      </div>

      {!isArchivePage && (
        <div className="event-filter-panel__buttons">
          <Button
            className={functions.classnames(
              filter['dates'] && filter['dates'] !== 'picker' && 'selected'
            )}
            onClick={() =>
              handleSelectionButtonClick(dateList, 'Tarih', 'dates')
            }
          >
            {app.settings.translations['filter-dates']}
            <Icon name="chev-right" />
          </Button>
          <Button
            className={functions.classnames(filter['categoryId'] && 'selected')}
            onClick={() =>
              handleSelectionButtonClick(categories, 'Tür', 'categoryId')
            }
          >
            {app.settings.translations['filter-categories']}
            <Icon name="chev-right" />
          </Button>
          <Button
            className={functions.classnames(
              filter['isFree'] !== undefined && 'selected'
            )}
            onClick={() =>
              handleSelectionButtonClick(
                priceList,
                app.settings.translations['fee'],
                'isFree'
              )
            }
          >
            {app.settings.translations['fee']}
            <Icon name="chev-right" />
          </Button>
          <Button
            className={functions.classnames(filter['placeId'] && 'selected')}
            onClick={() =>
              handleSelectionButtonClick(places, 'Mekan', 'placeId')
            }
          >
            {app.settings.translations['filter-places']}
            <Icon name="chev-right" />
          </Button>
          {!festivalID && (
            <Button
              className={functions.classnames(
                filter['festivalId'] && 'selected'
              )}
              onClick={() =>
                handleSelectionButtonClick(
                  filterFestival,
                  'Festival',
                  'festivalId'
                )
              }
            >
              {app.settings.translations['filter-festivals']}
              <Icon name="chev-right" />
            </Button>
          )}
          <div className="event-filter-panel__buttons__append">
            <Button onClick={() => handleFilter()} className="more">
              {app.settings.translations['filter-apply']}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default EventFilterPanel
