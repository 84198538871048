import React, { useCallback, useEffect, useState } from 'react'
import { IEventListProps } from './types'
import Router from 'next/router'
import { Container, Row } from '@/components/base/gridview'
import EventFilter from '@/components/widgets/events/event-filter'

import { useMounted } from '@wap-client/core'
import EventFilterPanel from '../event-filter-panel'
import EventListPage from './EventListPage'

const EventList: React.FC<IEventListProps> = ({
  isHomePage = false,
  festivalID = '',
  isArchivePage = false,
  isGlobalEvents = false,
}) => {
  const [isPanel, setIsPanel] = useState(false)
  const [page, setPage] = useState(1)
  const isMounted = useMounted()

  const handleLoadMore = useCallback(() => setPage((state) => state + 1), [])

  useEffect(() => {
    const handleStart = () => setPage(1)
    Router.events.on('routeChangeStart', handleStart)

    return () => {
      Router.events.off('routeChangeStart', handleStart)
    }
  }, [])

  useEffect(() => {
    if (isPanel) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [isPanel])

  return (
    <Container size="extended">
      <div className="event-list">
        <EventFilterPanel
          setIsPanel={setIsPanel}
          isPanel={isPanel}
          festivalID={festivalID}
          isArchivePage={isArchivePage}
        />

        <EventFilter
          setIsPanel={setIsPanel}
          isPanel={isPanel}
          festivalID={festivalID}
          isArchivePage={isArchivePage}
        />
        <div className="event-list-events">
          <Row className="listed">
            {isMounted &&
              Array.from({ length: page }, (_, i) => i + 1).map((item) => (
                <EventListPage
                  festivalID={festivalID}
                  isHomePage={isHomePage}
                  isLastPage={item === page}
                  key={item}
                  page={item}
                  loadMore={handleLoadMore}
                  isArchivePage={isArchivePage}
                  isGlobalEvents={isGlobalEvents}
                />
              ))}
          </Row>
        </div>
      </div>
    </Container>
  )
}

export default EventList
